import { IProduct } from '../../models';

export const products: IProduct[] = [
    {
        id: 'tax-free-savings',
        journeyId: 'tax-free',
        productTitle: 'Tax free savings',
        productPageHeading: 'You have chosen to open a tax-free savings account (TFSA)',
        productCode: 'TFSA',
        text: `<p>Maximise the long-term growth of your savings by benefiting from a Tax-Free Savings Account (TFSA). The growth and income received on a TFSA investment are tax free.<br><br></p>
                <div class="d-flex flex-align-center flex-justify-center">
                  <div class="flex-grow-1 text-center">
                    <div class="smallx">R10 000</div>
                    <div class="small">Min lump sum<br>&nbsp;</div>
                    <br>
                    <div class="smallx">R36 000</div>
                    <div class="small">Max contribution<br>p/a</div>
                  </div>
                  <div> OR </div>
                  <div class="flex-grow-1 text-center">
                    <div class="smallx">R500</div>
                    <div class="small">Min debit order<br>p/m</div>
                    <br>
                    <div class="smallx">R3000</div>
                    <div class="small">Max debit order<br>p/m</div>
                  </div>
                </div>
                <br>
                `,
        details: {
            id: 'tax-free-savings-account',
            productDetailsTitle: 'Tax-free savings account (TFSA)',
            text: `
            <div class="dialog-content-section">
              <h5>What happens with your money?</h5>
              <p>
                The fund managers, in this case Ninety One, invest your money in a portfolio of assets such as equities, bonds, cash and listed property, based on your fund selection.
              </p>
              <p>
               We then manage the investment on your behalf according to the funds’ objectives, for example, beating inflation over a certain time period. This plays a key role in determining the return on your investment.
              </p>
            </div>
            <div class="dialog-content-section">
              <h5>How much can you invest in a TFSA?</h5>
              <p>Minimum investment amount: R500 per month or R10 000 as a lump sum<br>
                  Maximum investment amount: R3 000 per month, R36 000 per tax year and a R500 000 lifetime limit per person
              </p>
            </div>
            <div class="dialog-content-section">
              <h5>What happens when you breach these limits?</h5>
              <p>
                If you exceed the maximum investment amounts, you will be subject to 40% tax on the excess amount.<br>
                If you withdraw money from an existing TFSA and re-pay it into any of these accounts, it will be regarded as a new contribution and will count towards your investment limits. So, make sure you stick to the lifetime and annual limits.
              </p>
            </div>
          `,
        },
        isContactOnly: false,
        showReg28Indicator: false,
        selectedCurrency: 'ZAR',
        minimumInvestment: 10000,
    },
    {
        id: 'living-annuity',
        journeyId: 'income',
        productTitle: 'Living annuity',
        productPageHeading: 'You have chosen to open a living annuity account',
        productCode: 'LA',
        text: `<p>A living annuity provides you with flexible income options after retirement. Ninety One Living Annuity investors have a range of unit trusts from which to choose.</p>
                <p>Please note that you cannot currently apply for this product online just yet.</p>
                <p>Read more or please contact us by clicking on the button below</p>`,
        details: {
            id: 'living-annuity-investment',
            productDetailsTitle: 'Living Annuity Investment',
            text: 'The Ninety One Living Annuity provides investors with a tax-efficient way to save.',
        },
        isContactOnly: true,
        showReg28Indicator: false,
        selectedCurrency: 'ZAR',
        minimumInvestment: 10000,
    },
    {
        id: 'offshore-foreign-currency-funds',
        journeyId: 'offshore',
        productTitle: 'Foreign currency funds',
        productPageHeading: 'You have chosen to invest offshore',
        productCode: 'GIB',
        text: `<p>Convert your rands into a foreign currency (e.g. US dollars) and invest directly in an international fund to have full exposure to offshore markets.</p>
                <p>Minimum investment<br><span class="font-weight-500">US$25,000</span><br>or the equivalent in euros and sterling</p>`,
        details: {
            id: 'offshore-foreign-currency-funds',
            productDetailsTitle: 'Here’s how it works',
            text: `
            <div class="dialog-content-section">
              <p>
               South Africans have an annual discretionary allowance of R1 million and an annual investment allowance of R10 million they can use to invest offshore. You do not need prior approval from the South African Revenue Service or the Reserve Bank to use the discretionary allowance. But for amounts above R1 million, you will need clearance from the authorities.
              </p>
              <p>
               You can use your annual allowance to convert rands into foreign currency. This will enable you to invest directly into offshore funds. Alternatively, you can invest in rand-based funds that offer exposure to global assets.
              </p>
              <h5>Foreign currency funds</h5>
              <p>
              Convert your rands into a foreign currency (e.g. US dollars) and invest directly in a foreign-domiciled fund. This could suit you if you’re looking to have full exposure to offshore markets using a foreign currency-based fund.
              </p>
              <p class="text-primary">
              Minimum investment: US$25,000 or equivalent approved currency lump sum
              </p>
            </div>
            <div class="dialog-content-section">
              <h6>We offer investors access to leading funds across the globe via a single, integrated investment platform in our Ninety One Global Investment Portfolio.</h6>
            </div>`,
        },
        isContactOnly: false,
        showReg28Indicator: false,
        selectedCurrency: 'USD',
        minimumInvestment: 25000,
    },
    {
        id: 'offshore-rand-based-funds',
        journeyId: 'offshore',
        productTitle: 'Rand-based funds',
        productPageHeading: 'You have chosen to invest offshore',
        productCode: 'INVIB',
        text: `<p>Gain access to global markets using a rand-based fund that will give you full exposure to offshore markets.</p><p>You can invest from as little as R1 000 a month.</p>`,
        details: {
            id: 'offshore-rand-based-funds',
            productDetailsTitle: 'Here’s how it works',
            text: `
            <div class="dialog-content-section">
              <p>
              South Africans do not need to make use of their annual discretionary allowance of R1 million or annual investment allowance of R10 million to invest offshore. You can invest in Rands with our international feeder funds, which will then invest into offshore markets.
              </p>
              <h5>Rand-based funds</h5>
              <p>
              Gain 100% offshore exposure with our international feeder funds which could suit you if you would like to gain access to global markets using a rand-based fund.
              </p>
            </div>`,
        },
        isContactOnly: false,
        showReg28Indicator: false,
        selectedCurrency: 'ZAR',
        minimumInvestment: 10000,
    },
    {
        id: 'retirement',
        journeyId: 'retirement',
        productTitle: 'Retirement annuity',
        productPageHeading: 'You have chosen to open a retirement annuity (RA)',
        productCode: 'INVRA',
        text: `<p>The Ninety One Retirement Annuity is a tax-efficient and convenient way to save for your retirement, offering simple online administration and access to funds that will match your investment profile.</p>
                <p>When you retire, converting your savings to income is easy.</p>`,
        details: {
            id: 'retirement-annuity-investment',
            productDetailsTitle: 'Retirement Annuity (RA)',
            text: `
            <div class="dialog-content-section">
              <p>
              With extensive tax benefits and protection of your savings until you retire, a retirement annuity (RA) is the ideal solution to make provision for a comfortable retirement.
              </p>
              <h5>What happens with your money?</h5>
              <p>
              The fund managers invest your money in a portfolio of assets such as equities, bonds, cash and listed property, based on your fund selection.
              </p>
              <p>
              The investment is managed on your behalf according to the fund’s objectives, for example, beating inflation over a certain time period. This plays a key role in determining the return on your investment.
              </p>
            </div>
            <div class="dialog-content-section">
              <h5>Can I access my money?</h5>
              <div class="dialog-content-inner-header">Before retirement</div>
              <p>- There is no access until age 55, with some exceptions such as formal emigration or ill health (permanent disability).</p>
              <div class="dialog-content-inner-header">At retirement (from age 55)</div>
              <p>- You can take a maximum of one-third of the investment amount as a lump sum.</p>
              <p>- You need to invest the balance, or the full value – if you haven’t taken a lump sum – in a compulsory annuity to provide you with retirement income.</p>
              <p>- You may take the full amount of your benefit as a lump sum, if the value is equal to or less than R247 500.</p>
            </div>
          `,
        },
        isContactOnly: false,
        showReg28Indicator: true,
        selectedCurrency: 'ZAR',
        minimumInvestment: 10000,
    },
    {
        id: 'unit-trusts',
        journeyId: 'just-investing',
        productTitle: 'Unit trusts',
        productPageHeading: 'You have chosen to open a unit trust account',
        productCode: 'INVIB',
        text: `<p>A unit trust allows you to get access to professionally managed investments by buying a portion of a fund, also known as units.</p>
                <p>The Ninety One Investment Portfolio gives you cost-effective access to a wide range of local and offshore unit trusts across asset classes.</p>
                <p>You can invest from as little as R1 000 a month.</p>`,
        details: {
            id: 'unit-trusts',
            productDetailsTitle: 'Unit trusts',
            text: `
      <div class="dialog-content-section">
        <h5>What happens with your money?</h5>
        <p>
        The fund managers invest your money in a portfolio of assets such as equities, bonds, cash and listed property, based on your fund selection.
        </p>
        <p>
        The investment is managed on your behalf according to the fund’s objectives, for example, beating inflation over a certain time period. This plays a key role in determining the return on your investment.
        </p>
      </div>
      <div class="dialog-content-section">
        <h5>What amounts may be invested?</h5>
        <p>Minimum recurring investment: R1 000 per month<br>
        Minimum lump sum: R100 000<br>
        Maximum: No limit
        </p>
      </div>
      <div class="dialog-content-section">
        <h5>When can you access your money?</h5>
        <p>
        The investment term is flexible – you can add to your investment or withdraw whenever you want.
        </p>
      </div>
    `,
        },
        isContactOnly: false,
        showReg28Indicator: false,
        selectedCurrency: 'ZAR',
        minimumInvestment: 10000,
    },
];
