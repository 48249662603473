import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { ApplicationInsights, DistributedTracingModes, IExceptionTelemetry, Util } from '@microsoft/applicationinsights-web';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const RoleTag = 'ai.cloud.role';

@Injectable({
    providedIn: 'root',
})
export class MonitoringService {
    private readonly AppInsights: ApplicationInsights;

    constructor(private readonly router: Router) {
        if (environment.appInsightsConfig && environment.appInsightsConfig.instrumentationKey) {
            this.AppInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsightsConfig.instrumentationKey,
                    isCookieUseDisabled: true,
                    autoTrackPageVisitTime: true,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    enableCorsCorrelation: true,
                },
            });

            this.AppInsights.loadAppInsights();

            this.AppInsights.addTelemetryInitializer((item) => {
                if (!item.baseData.properties) {
                    item.baseData.properties = {};
                }
                item.baseData.properties.build = environment.build;
            });
            this.AppInsights.addTelemetryInitializer((item) => {
                if (item.tags == null) {
                    item.tags = [];
                }
                item.tags[RoleTag] = environment.appInsightsConfig.roleName;
            });
        }

        this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
                this.logPageView(
                    `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
                    event.urlAfterRedirects,
                    undefined,
                );
            }
        });

        this.AppInsights.trackPageView({
            uri: this.router.url,
            properties: {},
        });
    }

    public logPageView(name: string, url?: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        if (this.AppInsights) {
            this.AppInsights.context.telemetryTrace.traceID = Util.newId();
            this.AppInsights.trackPageView({
                uri: url,
                name,
                properties: properties || {},
                measurements,
            });
        }
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        if (this.AppInsights) {
            this.AppInsights.trackEvent({
                name,
                measurements,
                properties: properties || {},
            });
        }
    }

    public logError(error: any, errorId?: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        if (this.AppInsights) {
            let appInsightsError: IExceptionTelemetry;
            properties = properties || {};
            if (typeof error === 'string') {
                appInsightsError = {
                    id: errorId || error,
                    exception: new Error(error),
                };
            } else if (error instanceof HttpErrorResponse || ('rejection' in error && error.rejection instanceof HttpErrorResponse)) {
                let httpError: HttpErrorResponse;
                if ('rejection' in error && error.rejection instanceof HttpErrorResponse) {
                    httpError = error.rejection;
                } else {
                    httpError = error;
                }
                appInsightsError = {
                    id: errorId || `HTTP ${httpError.status}`,
                    exception: new Error(`HTTP ${httpError.status}`),
                };
                properties = {
                    ...properties,
                    status: '' + httpError.status,
                    statusText: httpError.statusText,
                    type: '' + httpError.type,
                    url: httpError.url,
                    error: JSON.stringify(httpError),
                };
                for (const prop of httpError.headers.keys()) {
                    properties[`header_${prop}`] = httpError.headers.get(prop);
                }
            } else if (error instanceof Error) {
                appInsightsError = {
                    id: errorId,
                    error,
                };
            } else {
                appInsightsError = {
                    id: errorId || 'Unknown error',
                    exception: new Error(errorId || 'Unknown error'),
                };
                properties = {
                    ...properties,
                    errorJson: JSON.stringify(error),
                };
            }

            this.AppInsights.trackException({
                ...appInsightsError,
                properties: properties,
                measurements: measurements,
            });
        }
    }

    setAuthenticatedUserId(userId: string): void {
        if (this.AppInsights) {
            this.AppInsights.setAuthenticatedUserContext(userId);
        }
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}
