import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FundsService } from '../../services';
import * as fromActions from '../actions';
import { InstrumentsService } from '../../services/instruments-service/instruments.service';
import { productMapping } from '../../views/product/constants';
import { CommonLobSystem } from '../../auth/auth.enums';

@Injectable()
export class FundsEffects {
    constructor(private actions$: Actions, private fundsService: FundsService, private instrumentsService: InstrumentsService) {}

    loadAllFunds$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromActions.loadFunds),
            switchMap((data) => {
                return this.fundsService.getFunds(data.productId).pipe(map((funds) => ({ productId: data.productId, funds })));
            }),
            switchMap(({ productId, funds }) => {
                const productCode = productMapping[productId];
                const systemCode = CommonLobSystem.IMS;
                return this.instrumentsService
                    .getAvailableInstruments(systemCode, productCode)
                    .pipe(map((instruments) => ({ funds, instruments })));
            }),
            switchMap(({ funds, instruments }) => {
                const fundsAndInstruments = funds.map((fund) => {
                    const fundInstrument = instruments.find((f) => f.instrumentNumber === fund.instrumentId);
                    fund.instrument = {
                        selectedInstrumentType: fundInstrument.instrumentTypeCode,
                        selectedInstrument: fundInstrument,
                        percent: null,
                        amount: null,
                    };
                    return fund;
                });

                return this.fundsService.getFees(fundsAndInstruments).pipe(
                    map((fees) => {
                        const mergedFunds = fundsAndInstruments.map((fund) => {
                            fund.fees = fees.find((x) => x.instrumentNumber === fund.instrumentId);
                            return fund;
                        });
                        return fromActions.loadFundsSuccess({ funds: mergedFunds });
                    }),
                    catchError(() => of(fromActions.loadFundsError())),
                );
            }),
        );
    });
}
