import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RisksState } from '../../models';
import { RiskSelectors } from '../reducers';

export const selectRiskState = createFeatureSelector<RisksState>('risks');

export const selectRiskIds = createSelector(selectRiskState, RiskSelectors.selectRiskIds);
export const selectRiskEntities = createSelector(selectRiskState, RiskSelectors.selectRiskEntities);
export const selectAllRisks = createSelector(selectRiskState, RiskSelectors.selectAllRisks);
export const selectRiskTotal = createSelector(selectRiskState, RiskSelectors.selectTotalRisks);
export const getSelectedRiskIds = createSelector(selectRiskState, RiskSelectors.getSelectedRiskIds);
