import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IProduct, ProductsState } from '../../models';
import * as ProductActions from '../actions/products.actions';

export const productEntityAdapter: EntityAdapter<IProduct> = createEntityAdapter<IProduct>({});

export const productInitialState: ProductsState = productEntityAdapter.getInitialState({
    selectedProductId: '',
});

const productReducer = createReducer(
    productInitialState,
    on(ProductActions.setSelectedProduct, (state, { id }) => {
        return Object.assign({}, state, { selectedProductId: id });
    }),
    on(ProductActions.clearSelectedProduct, (state) => {
        return Object.assign({}, state, { selectedProductId: '' });
    }),
    on(ProductActions.addProduct, (state, { product }) => {
        return productEntityAdapter.addOne(product, state);
    }),
    on(ProductActions.setProduct, (state, { product }) => {
        return productEntityAdapter.setOne(product, state);
    }),
    on(ProductActions.upsertProduct, (state, { product }) => {
        return productEntityAdapter.upsertOne(product, state);
    }),
    on(ProductActions.addProducts, (state, { products }) => {
        return productEntityAdapter.addMany(products, state);
    }),
    on(ProductActions.upsertProducts, (state, { products }) => {
        return productEntityAdapter.upsertMany(products, state);
    }),
    on(ProductActions.updateProduct, (state, { update }) => {
        return productEntityAdapter.updateOne(update, state);
    }),
    on(ProductActions.updateProducts, (state, { updates }) => {
        return productEntityAdapter.updateMany(updates, state);
    }),
    on(ProductActions.mapProduct, (state, { entityMap }) => {
        return productEntityAdapter.mapOne(entityMap, state);
    }),
    on(ProductActions.mapProducts, (state, { entityMap }) => {
        return productEntityAdapter.map(entityMap, state);
    }),
    on(ProductActions.deleteProduct, (state, { id }) => {
        return productEntityAdapter.removeOne(id, state);
    }),
    on(ProductActions.deleteProducts, (state, { ids }) => {
        return productEntityAdapter.removeMany(ids, state);
    }),
    on(ProductActions.deleteProductsByPredicate, (state, { predicate }) => {
        return productEntityAdapter.removeMany(predicate, state);
    }),
    on(ProductActions.loadProductsSuccess, (state, { products }) => {
        return productEntityAdapter.setAll(products, state);
    }),
    on(ProductActions.clearProducts, (state) => {
        return productEntityAdapter.removeAll({ ...state, selectedProductId: null });
    }),
);

export function ProductsReducer(state: ProductsState | undefined, action: Action): any {
    return productReducer(state, action);
}

const getSelectedProductId = (state: ProductsState) => state.selectedProductId;

const productEntityAdapterSelectors = productEntityAdapter.getSelectors();

export const ProductSelectors = {
    selectProductIds: productEntityAdapterSelectors.selectIds,
    selectProductEntities: productEntityAdapterSelectors.selectEntities,
    selectAllProducts: productEntityAdapterSelectors.selectAll,
    selectTotalProducts: productEntityAdapterSelectors.selectTotal,
    getSelectedProductId: getSelectedProductId,
};
