import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
    production: true,
    title: '',
    appInsightsConfig: {
        instrumentationKey: '7c94f544-66c9-4c0a-aaff-1c17b47500dc',
        roleName: 'DirectInvestor-PROD',
    },
    apiConfig: {
        baseUrl: 'https://investdirect.secure.ninetyone.com/api',
        registerEndpoint: '/register',
        documentEndpoint: '/documents',
    },
    apexConfig: {
        baseUrl: 'https://online.secure.ninetyone.com',
        guestInvestorEndpoint: '/',
        loginUrl: 'https://login.secure.ninetyone.com/login',
    },
    cdnConfig: {
        useCdn: false,
        baseUrl: 'https://cdn-ninetyone-webartefacts.azureedge.net',
        factSheetsEndpoint: '/factsheets',
    },
    build: 'prod',
    googleAnalytics: {
        key: 'G-3H09Y36PPF',
    },
    enableAnimations: true,
    useLocalStorageForNgrx: false,
};
