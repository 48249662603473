import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';

const APEX_LOGIN_URL = environment.apexConfig.loginUrl;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './mobile-menu.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    public mobileMenuOpened: boolean;
    public mobileMenuHinted: boolean;
    public apexLoginUrl: string;

    constructor() {}

    ngOnInit(): void {
        this.apexLoginUrl = APEX_LOGIN_URL;
    }

    openMobileMenu() {
        this.mobileMenuOpened = true;
    }

    closeMobileMenu() {
        this.mobileMenuOpened = false;
    }

    hintMenu() {
        this.mobileMenuHinted = true;
    }

    unHintMenu() {
        this.mobileMenuHinted = false;
    }
}
