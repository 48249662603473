import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IJourney, JourneyState } from '../../models';
import * as JourneyActions from '../actions/journeys.actions';

export const journeyEntityAdapter: EntityAdapter<IJourney> = createEntityAdapter<IJourney>({});

export const journeyInitialState: JourneyState = journeyEntityAdapter.getInitialState({
    selectedJourneyId: '',
});

const journeyReducer = createReducer(
    journeyInitialState,
    on(JourneyActions.setSelectedJourney, (state, { id }) => {
        return Object.assign({}, state, { selectedJourneyId: id });
    }),
    on(JourneyActions.clearSelectedJourney, (state) => {
        return Object.assign({}, state, { selectedJourneyId: '' });
    }),
    on(JourneyActions.addJourney, (state, { journey }) => {
        return journeyEntityAdapter.addOne(journey, state);
    }),
    on(JourneyActions.setJourney, (state, { journey }) => {
        return journeyEntityAdapter.setOne(journey, state);
    }),
    on(JourneyActions.upsertJourney, (state, { journey }) => {
        return journeyEntityAdapter.upsertOne(journey, state);
    }),
    on(JourneyActions.addJourneys, (state, { journeys }) => {
        return journeyEntityAdapter.addMany(journeys, state);
    }),
    on(JourneyActions.upsertJourneys, (state, { journeys }) => {
        return journeyEntityAdapter.upsertMany(journeys, state);
    }),
    on(JourneyActions.updateJourney, (state, { update }) => {
        return journeyEntityAdapter.updateOne(update, state);
    }),
    on(JourneyActions.updateJourneys, (state, { updates }) => {
        return journeyEntityAdapter.updateMany(updates, state);
    }),
    on(JourneyActions.mapJourney, (state, { entityMap }) => {
        return journeyEntityAdapter.mapOne(entityMap, state);
    }),
    on(JourneyActions.mapJourneys, (state, { entityMap }) => {
        return journeyEntityAdapter.map(entityMap, state);
    }),
    on(JourneyActions.deleteJourney, (state, { id }) => {
        return journeyEntityAdapter.removeOne(id, state);
    }),
    on(JourneyActions.deleteJourneys, (state, { ids }) => {
        return journeyEntityAdapter.removeMany(ids, state);
    }),
    on(JourneyActions.deleteJourneysByPredicate, (state, { predicate }) => {
        return journeyEntityAdapter.removeMany(predicate, state);
    }),
    on(JourneyActions.loadJourneysSuccess, (state, { journeys }) => {
        return journeyEntityAdapter.setAll(journeys, state);
    }),
    on(JourneyActions.clearJourneys, (state) => {
        return journeyEntityAdapter.removeAll({ ...state, selectedJourneyId: null });
    }),
);

export function JourneysReducer(state: JourneyState | undefined, action: Action): any {
    return journeyReducer(state, action);
}

const getSelectedJourneyId = (state: JourneyState) => state.selectedJourneyId;

const journeyEntityAdapterSelectors = journeyEntityAdapter.getSelectors();

export const JourneySelectors = {
    selectJourneyIds: journeyEntityAdapterSelectors.selectIds,
    selectJourneyEntities: journeyEntityAdapterSelectors.selectEntities,
    selectAllJourneys: journeyEntityAdapterSelectors.selectAll,
    selectTotalJourneys: journeyEntityAdapterSelectors.selectTotal,
    getSelectedJourneyId: getSelectedJourneyId,
};
