<header class="header">
    <div class="header-content">
        <div class="main-nav">
            <div class="main-nav-inner">
                <div class="logo">
                    <a href="/" class="sc-eqIVtm dKDDlb a-logo__link">
                        <div class="logo-icon" aria-hidden="false">
                            <span class="logo-icon-text">NinetyOne</span>
                            <svg viewBox="0 0 98 48" aria-hidden="true">
                                <path
                                    d="M19 0v48h-9V0h9zm10 0v48h-9V0h9zm40.559 24.004c.37 0 .624.023.994.138l3.859 2.815c.37.807.578 1.776.578 2.907v.784h-9.014c.07 2.214 1.526 3.506 3.606 3.506 1.803 0 2.981-.945 3.397-2.33l1.756.577C73.973 34.616 72.193 36 69.56 36c-3.513 0-5.64-2.4-5.64-5.998 0-3.599 2.612-5.998 5.64-5.998zm-28.517-4.707c.555 0 1.11.047 1.641.162l5.385 3.899c.647 1.2 1.017 2.652 1.017 4.244 0 4.96-3.56 8.305-8.043 8.305-4.483 0-8.042-3.345-8.042-8.305 0-4.96 3.559-8.305 8.042-8.305zm16.454 4.707c.139 0 .3 0 .439.023l3.305 2.4c.139.414.208.876.208 1.383v7.96h-2.034v-7.59c0-1.523-.832-2.377-2.357-2.377-1.41 0-2.612.97-3.467 2.215v7.751h-2.033V24.235h2.033v1.5c.81-.854 2.335-1.731 3.906-1.731zm-16.454-2.677c-3.536 0-5.777 2.584-5.777 6.275 0 3.691 2.241 6.275 5.777 6.275s5.778-2.584 5.778-6.275c0-3.69-2.242-6.275-5.778-6.275zM69.56 25.85c-1.803 0-3.236 1.108-3.56 3.046h7.072c-.161-2.03-1.71-3.046-3.512-3.046zM9 0v24H0V0h9zm80.172 4.615l3.097 7.359.3 1.407 3.374-8.766H98l-6.517 16.379-1.78-.577 1.919-4.775-4.576-11.027h2.126zm-8.618-3.462l2.034.67v2.791h2.773l-.6 1.845h-2.173v6.875c0 .761.439 1.246 1.317 1.246.393 0 .763-.093 1.271-.346l.532 1.615c-.855.392-1.526.53-2.311.53-1.757 0-2.843-.992-2.843-2.907V6.46h-2.288l.601-1.845h1.687v-3.46zm-8.9 3.23c.37 0 .625.023.994.139l3.86 2.814c.37.807.578 1.776.578 2.907v.784h-9.014c.07 2.215 1.526 3.507 3.606 3.507 1.803 0 2.981-.946 3.397-2.33l1.756.576c-.762 2.215-2.542 3.6-5.176 3.6-3.513 0-5.64-2.4-5.64-5.999 0-3.599 2.612-5.998 5.64-5.998zm-11.836 0c.139 0 .3 0 .44.023l3.304 2.4c.139.415.208.876.208 1.384v7.958h-2.034v-7.59c0-1.522-.832-2.375-2.357-2.375-1.41 0-2.611.968-3.466 2.214v7.751h-2.034V4.614h2.034v1.5c.809-.854 2.334-1.73 3.905-1.73zm-8.831.231v11.535h-2.034V4.614h2.034zM36.4 0l7.441 12.665V0h2.22v16.148h-2.497L36.124 3.483v12.665h-2.219V0h2.496zm35.254 6.229c-1.803 0-3.236 1.107-3.56 3.045h7.072c-.161-2.03-1.71-3.045-3.512-3.045zM49.97.118a1.37 1.37 0 0 1 0 2.74 1.37 1.37 0 1 1 0-2.74z"
                                ></path>
                                <path fill="currentColor" d="M19 24v24h-9V24h9zm10 0v24h-9V24h9z"></path>
                            </svg>
                        </div>
                    </a>
                </div>
                <div class="sc-kxLnmX kMUeRn nav-container">
                    <nav>
                        <ul class="no-hover">
                            <li>
                                <a href="https://ninetyone.com/south-africa" target="_blank"
                                    ><span class="sc-btzYZH gMzgOw">Back to ninetyone.com</span></a
                                >
                            </li>
                            <li>
                                <a href="{{ apexLoginUrl }}" target="_blank"><span class="sc-btzYZH gMzgOw">Login</span></a>
                            </li>
                            <li>
                                <a href="https://ninetyone.com/en/south-africa/contact-us" target="_blank"
                                    ><span class="sc-btzYZH gMzgOw">Contact us</span></a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="mobile-button-container" fxShow.xs="true" fxShow.sm="true" fxShow="false">
                    <button class="btn btn-transparent" (click)="openMobileMenu()">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="39" height="23" viewBox="0 0 39 23">
                                <line
                                    id="Line_538"
                                    data-name="Line 538"
                                    x2="39"
                                    transform="translate(0 2)"
                                    fill="none"
                                    stroke="#009d80"
                                    stroke-width="4"
                                />
                                <line
                                    id="Line_539"
                                    data-name="Line 539"
                                    x2="39"
                                    transform="translate(0 11)"
                                    fill="none"
                                    stroke="#009d80"
                                    stroke-width="4"
                                />
                                <line
                                    id="Line_540"
                                    data-name="Line 540"
                                    x2="20"
                                    transform="translate(0 21)"
                                    fill="none"
                                    stroke="#009d80"
                                    stroke-width="4"
                                />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</header>
<div
    class="sc-bZVNgQ hqyJkw o-fullscreenNav"
    [class.-open]="mobileMenuOpened"
    [class.-hint]="mobileMenuHinted"
    role="dialog"
    aria-hidden="false"
>
    <div class="sc-Ehqfj lnJbFy o-fullscreenNavContent -open">
        <div class="sc-jiTwWT fgUrxo o-fullscreenNavContent__smallScreenContent">
            <div class="sc-dcOKER klzsJ o-smallScreenNav">
                <div>
                    <nav class="sc-feJyhm fRrBax">
                        <ul class="sc-iELTvK kcGYtj m-navigationList sc-ibnDSj ffJkuS">
                            <li class="sc-jwKygS jiaaSo">
                                <a href="https://ninetyone.com/south-africa" target="_blank"
                                    ><span class="sc-btzYZH gMzgOw">Back to Ninety One.com</span></a
                                >
                            </li>
                            <li class="sc-jwKygS jiaaSo">
                                <a href="{{ apexLoginUrl }}" target="_blank"><span class="sc-btzYZH gMzgOw">Login</span></a>
                            </li>
                            <li class="sc-jwKygS jiaaSo">
                                <a href="https://ninetyone.com/en/south-africa/contact-us" target="_blank"
                                    ><span class="sc-btzYZH gMzgOw">Contact us</span></a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <button
        class="sc-liPmeQ fAezhi o-fullscreenNav__closeTarget"
        (click)="closeMobileMenu()"
        (mouseenter)="hintMenu()"
        (mouseleave)="unHintMenu()"
    >
        <div id="" class="sc-bdVaJa cHeARK sc-lljKfs dkgJHE a-icon -medium -close" aria-hidden="false">
            <span class="sc-bwzfXH hnbUpB">Close menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="28.378" height="28.378" viewBox="0 0 28.378 28.378">
                <path
                    id="ic_close_24px"
                    d="M33.378,7.858,30.52,5,19.189,16.331,7.858,5,5,7.858,16.331,19.189,5,30.52l2.858,2.858L19.189,22.047,30.52,33.378l2.858-2.858L22.047,19.189Z"
                    transform="translate(-5 -5)"
                    fill="#00a17d"
                />
            </svg>
        </div>
    </button>
</div>
