import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

if (environment.googleAnalytics.key) {
    const analyticsScript = document.createElement('script');
    analyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics.key}`;
    analyticsScript.async = true;
    document.body.appendChild(analyticsScript);

    const gaPrepScript = document.createElement('script');
    gaPrepScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalytics.key}');`;
    document.body.appendChild(gaPrepScript);
}

try {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => {
            // Handle runtime error in angular
            console.error('Runtime Error:', err);
        });
} catch (err) {
    // handle angular loading error here.
    console.error('Loading Error:', err);
    throw err; // pass it back to the console
}
