import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RiskMappingsState } from '../../models';
import { RiskMappingsSelectors } from '../reducers';

export const selectRiskMappingState = createFeatureSelector<RiskMappingsState>('riskMappings');

export const selectRiskMappingIds = createSelector(selectRiskMappingState, RiskMappingsSelectors.selectRiskMappingIds);
export const selectRiskMappingEntities = createSelector(selectRiskMappingState, RiskMappingsSelectors.selectRiskMappingEntities);
export const selectAllRiskMappings = createSelector(selectRiskMappingState, RiskMappingsSelectors.selectAllRiskMappings);
export const selectTotalRiskMappings = createSelector(selectRiskMappingState, RiskMappingsSelectors.selectTotalRiskMappings);
export const getSelectedRiskMappingId = createSelector(selectRiskMappingState, RiskMappingsSelectors.selectCurrentRiskMappingId);

export const selectCurrentRiskMapping = createSelector(
    selectRiskMappingEntities,
    getSelectedRiskMappingId,
    (riskMappingEntities, riskMappingId) => riskMappingEntities[riskMappingId],
);
