import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    private _loading = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading.asObservable();

    public registerRouterHooks(router: Router): void {
        router.events.subscribe((routerEvent: RouterEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    checkRouterEvent(routerEvent: RouterEvent): void {
        if (routerEvent instanceof NavigationStart) {
            this.showLoading();
        }
        if (routerEvent instanceof NavigationEnd || routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
            this.hideLoading();
        }
    }

    public showLoading(): void {
        if (this._loading.value !== true) {
            this._loading.next(true);
        }
    }

    public hideLoading(): void {
        if (this._loading.value !== false) {
            setTimeout(() => {
                this._loading.next(false);
            }, 500);
        }
    }
}
