import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialImportModule } from './forms-framework/material-import/material-import.module';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, ReactiveFormsModule, MaterialImportModule, RouterModule],
    declarations: [LoaderComponent],

    exports: [LoaderComponent],
})
export class SharedModule {}
