import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IJourney } from '../../models';

export const setSelectedJourney = createAction('journey.selected.set', props<{ id: string }>());
export const clearSelectedJourney = createAction('journey.selected.clear');

export const loadJourneys = createAction('journeys.load');
export const loadJourneysSuccess = createAction('journeys.load.success', props<{ journeys: IJourney[] }>());
export const loadJourneysError = createAction('journeys.load.error');

export const addJourney = createAction('journey.add', props<{ journey: IJourney }>());
export const setJourney = createAction('journey.set', props<{ journey: IJourney }>());
export const upsertJourney = createAction('journey.upsert', props<{ journey: IJourney }>());
export const addJourneys = createAction('journeys.add', props<{ journeys: IJourney[] }>());
export const upsertJourneys = createAction('journeys.upsert', props<{ journeys: IJourney[] }>());
export const updateJourney = createAction('journey.update', props<{ update: Update<IJourney> }>());
export const updateJourneys = createAction('journeys.update', props<{ updates: Update<IJourney>[] }>());
export const mapJourney = createAction('journey.map', props<{ entityMap: EntityMapOne<IJourney> }>());
export const mapJourneys = createAction('journeys.map', props<{ entityMap: EntityMap<IJourney> }>());
export const deleteJourney = createAction('journey.delete', props<{ id: string }>());
export const deleteJourneys = createAction('journeys.delete', props<{ ids: string[] }>());
export const deleteJourneysByPredicate = createAction('journeys.delete.by.predicate', props<{ predicate: Predicate<IJourney> }>());
export const clearJourneys = createAction('journeys.clear');
