import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProductsService } from '../../services';
import * as fromActions from '../actions';

@Injectable()
export class ProductsEffects {
    constructor(private actions$: Actions, private productsService: ProductsService) {}

    loadAllProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadProducts),
            mergeMap(() =>
                this.productsService.getGetProducts().pipe(
                    map((products) => fromActions.loadProductsSuccess({ products: products })),
                    catchError(() => of(fromActions.loadProductsError())),
                ),
            ),
        ),
    );
}
