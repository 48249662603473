import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IRisk, RisksState } from '../../models';
import * as RiskActions from '../actions/risks.actions';

export const riskEntityAdapter: EntityAdapter<IRisk> = createEntityAdapter<IRisk>();

export const riskInitialState: RisksState = riskEntityAdapter.getInitialState({
    selectedRiskIds: [],
});

const riskReducer = createReducer(
    riskInitialState,
    on(RiskActions.setSelectedRisks, (state, { ids }) => {
        return Object.assign({}, state, { selectedRiskIds: ids });
    }),
    on(RiskActions.clearSelectedRisks, (state) => {
        return Object.assign({}, state, { selectedRiskIds: [] });
    }),
    on(RiskActions.loadRisksSuccess, (state, { risks }) => {
        return riskEntityAdapter.setAll(risks, state);
    }),
);

export function RisksReducer(state: RisksState | undefined, action: Action): any {
    return riskReducer(state, action);
}

const getSelectedRiskIds = (state: RisksState) => state.selectedRiskIds;

const riskEntityAdapterSelectors = riskEntityAdapter.getSelectors();

export const RiskSelectors = {
    selectRiskIds: riskEntityAdapterSelectors.selectIds,
    selectRiskEntities: riskEntityAdapterSelectors.selectEntities,
    selectAllRisks: riskEntityAdapterSelectors.selectAll,
    selectTotalRisks: riskEntityAdapterSelectors.selectTotal,
    getSelectedRiskIds: getSelectedRiskIds,
};
