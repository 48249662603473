import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './layout';

export const routes: Routes = [
    {
        path: '',
        component: PageLayoutComponent,
        data: {
            title: 'We are Ninety One | Ninety One',
            pageLayoutType: 'default-layout',
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./views/landing-page/landing-page.module').then((m) => m.LandingPageModule),
            },
            {
                path: 'product',
                loadChildren: () => import('./views/product/product.module').then((m) => m.ProductModule),
            },
            {
                path: 'user/login-selection',
                loadChildren: () => import('./views/user-selection-page/user-selection-page.module').then((m) => m.UserSelectionPageModule),
            },
            {
                path: 'user/need-to-know',
                loadChildren: () =>
                    import('./views/new-customer-landing-page/new-customer-landing-page.module').then(
                        (m) => m.NewCustomerLandingPageModule,
                    ),
            },
        ],
    },
    {
        path: 'user',
        component: PageLayoutComponent,
        data: {
            title: 'We are Ninety One | Ninety One',
            pageLayoutType: 'white-layout',
        },
        children: [
            {
                path: 'register',
                loadChildren: () => import('./views/registration/registration.module').then((m) => m.RegistrationModule),
            },
        ],
    },
    {
        path: 'error',
        loadChildren: () =>
            import('./views/error/internal-server-error/internal-server-error.module').then((m) => m.InternalServerErrorModule),
    },
    {
        path: 'not-found',
        loadChildren: () => import('./views/error/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
    },
    { path: '**', redirectTo: 'not-found' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
