import { EntityMap, EntityMapOne, Predicate, Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { IProduct } from '../../models';

export const setSelectedProduct = createAction('product.selected.set', props<{ id: string }>());
export const clearSelectedProduct = createAction('product.selected.clear');

export const loadProducts = createAction('products.load');
export const loadProductsSuccess = createAction('products.load.success', props<{ products: IProduct[] }>());
export const loadProductsError = createAction('products.load.error');

export const addProduct = createAction('product.add', props<{ product: IProduct }>());
export const setProduct = createAction('product.set', props<{ product: IProduct }>());
export const upsertProduct = createAction('product.upsert', props<{ product: IProduct }>());
export const addProducts = createAction('products.add', props<{ products: IProduct[] }>());
export const upsertProducts = createAction('products.upsert', props<{ products: IProduct[] }>());
export const updateProduct = createAction('product.update', props<{ update: Update<IProduct> }>());
export const updateProducts = createAction('products.update', props<{ updates: Update<IProduct>[] }>());
export const mapProduct = createAction('product.map', props<{ entityMap: EntityMapOne<IProduct> }>());
export const mapProducts = createAction('products.map', props<{ entityMap: EntityMap<IProduct> }>());
export const deleteProduct = createAction('product.delete', props<{ id: string }>());
export const deleteProducts = createAction('products.delete', props<{ ids: string[] }>());
export const deleteProductsByPredicate = createAction('products.delete.by.predicate', props<{ predicate: Predicate<IProduct> }>());
export const clearProducts = createAction('products.clear');
