import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAvailableInstrument } from './instrument.model';
import { CommonLobSystem } from '../../auth/auth.enums';

@Injectable({
    providedIn: 'root',
})
export class InstrumentsService {
    private readonly relativeUrl = '/Instruments';

    constructor(private readonly httpClient: HttpClient) {}

    getAvailableInstruments(systemCode: CommonLobSystem, productCode: string): Observable<IAvailableInstrument[]> {
        return this.httpClient.get<IAvailableInstrument[]>(
            `${environment.apiConfig.baseUrl}${this.relativeUrl}/available/${systemCode}/${productCode}`,
        );
    }
}
