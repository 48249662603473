import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonComponent } from './button.component';
@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    declarations: [ButtonComponent],
    exports: [ButtonComponent, MatProgressSpinnerModule],
})
export class ButtonModule {}
