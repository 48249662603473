import { ActionReducer, INIT } from '@ngrx/store';
import { State } from '../state';

export const storageMetaReducer = (reducer: ActionReducer<State>): ActionReducer<State> => {
    return (state, action) => {
        if (action.type === INIT) {
            const storageValue = localStorage.getItem('di-app-state');
            if (storageValue) {
                try {
                    return JSON.parse(storageValue);
                } catch {
                    localStorage.removeItem('di-app-state');
                }
            }
        }
        const nextState = reducer(state, action);
        localStorage.setItem('di-app-state', JSON.stringify(nextState));
        return nextState;
    };
};
