/*
These are all the funds configured to display site wide.
The funds are not linked to any product here
*/
export const configuredFunds: any = [
    {
        instrumentId: 10061,
        isin: 'ZAE000124798',
        fundName: 'Money Market Fund',
        investmentObjectives: `This is a short term money market fund which aims to preserve capital. It is best suited for short-term investments which can be easily converted into cash.`,
    },
    {
        instrumentId: 11392,
        isin: 'ZAE000171542',
        fundName: 'Diversified Income Fund',
        investmentObjectives: `This Fund is an actively managed globally integrated fixed income solution.`,
    },
    {
        instrumentId: 11389,
        isin: 'ZAE000171559',
        fundName: 'Cautious Managed Fund',
        investmentObjectives: `The Fund aims to generate income and growth
        and has delivered significantly less volatility than the market.`,
    },
    {
        instrumentId: 11861,
        isin: 'ZAE000159828',
        fundName: 'Opportunity Fund',
        investmentObjectives: `The Fund has a long-term track record of successfully limiting losses during times of equity market volatility
         and providing investors with the opportunity to create real wealth in the long term.`,
    },
    {
        instrumentId: 13391,
        isin: 'ZAE000216115',
        fundName: 'Worldwide Flexible Fund',
        investmentObjectives: `The Fund seeks to maximise flexibility and offshore opportunities for investors' total real returns on capital and income.`,
    },
    {
        instrumentId: 13469,
        isin: 'ZAE000216602',
        fundName: 'Equity Fund',
        investmentObjectives: `The Fund aims to deliver longer term capital growth. It has a solid long-term performance track record.`,
    },
    {
        instrumentId: 11395,
        isin: 'ZAE000171534',
        fundName: 'Equity Fund',
        investmentObjectives: `The Fund aims to deliver longer term capital growth. It has a solid long-term performance track record.`,
    },
    {
        instrumentId: 11567,
        isin: 'ZAE000176095',
        fundName: 'Global Multi-Asset Income Feeder Fund',
        investmentObjectives: `The Fund aims to achieve attractive income with capital growth over the long term.`,
    },
    {
        instrumentId: 11401,
        isin: 'ZAE000171518',
        fundName: 'Global Strategic Managed Feeder Fund',
        investmentObjectives: `This fund invests around the world in a mix of different assets and aims to deliver equity-like returns.`,
    },
    {
        instrumentId: 11398,
        isin: 'ZAE000171526',
        fundName: 'Global Franchise Feeder Fund',
        investmentObjectives: `The Fund has a differentiated approach to investing in quality, world leading companies from around the globe. It has an established track record and seeks to reduce uncertainty in an uncertain world.`,
    },
    {
        instrumentId: 12381,
        isin: 'LU0983164699',
        fundName: 'Global Multi-Asset Income Fund (USD)',
        investmentObjectives: `This Fund aims to achieve attractive income with capital growth over the long term.`,
    },
    {
        instrumentId: 12360,
        isin: 'LU0345768583',
        fundName: 'Global Strategic Managed Fund (USD)',
        investmentObjectives: `This Fund invests around the world in a mix of different assets and aims to deliver equity-like returns.`,
    },
    {
        instrumentId: 12375,
        isin: 'LU0426422076',
        fundName: 'Global Franchise Fund (USD)',
        investmentObjectives: `This Fund has a differentiated approach to investing in quality, world-leading companies from around the globe. It has an established track record and seeks to reduce uncertainty in an uncertain world.`,
    },
];

export enum FundSliderPosition {
    Low = 1,
    LowMedium = 2,
    Medium = 3,
    MediumHigh = 4,
    High = 5,
}

/*
These are all the funds mapped to their products by the instrumentId
*/
export const fundProductLookups: any = [
    {
        id: 1,
        order: 1,
        instrumentId: 10061,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: { currency: 'zar', minimumInitial: 50000, minimumInvestment: 50000 },
    },
    {
        id: 2,
        order: 2,
        instrumentId: 11392,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 3,
        order: 3,
        instrumentId: 11389,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.LowMedium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 4,
        order: 4,
        instrumentId: 11861,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.Medium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 5,
        order: 5,
        instrumentId: 13391,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.MediumHigh,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 6,
        order: 6,
        instrumentId: 13469,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 7,
        order: 7,
        instrumentId: 11567,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 8,
        order: 8,
        instrumentId: 11401,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 9,
        order: 9,
        instrumentId: 11398,
        productId: 'tax-free-savings',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    }, // TFSA End
    {
        id: 10,
        order: 1,
        instrumentId: 10061,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 50000,
            minimumInvestment: 50000,
        },
    },
    {
        id: 11,
        order: 2,
        instrumentId: 11392,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 12,
        order: 3,
        instrumentId: 11389,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.LowMedium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 13,
        order: 4,
        instrumentId: 11861,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.Medium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 14,
        order: 5,
        instrumentId: 13391,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.MediumHigh,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 15,
        order: 6,
        instrumentId: 11395,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 16,
        order: 7,
        instrumentId: 11567,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 17,
        order: 8,
        instrumentId: 11401,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 18,
        order: 9,
        instrumentId: 11398,
        productId: 'unit-trusts',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    }, // Unit Trusts End
    {
        id: 19,
        order: 1,
        instrumentId: 10061,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 50000,
            minimumInvestment: 50000,
        },
    },
    {
        id: 20,
        order: 2,
        instrumentId: 11392,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 21,
        order: 3,
        instrumentId: 11389,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.LowMedium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 22,
        order: 4,
        instrumentId: 11861,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.Medium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 23,
        order: 5,
        instrumentId: 13391,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.MediumHigh,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 24,
        order: 6,
        instrumentId: 11395,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 25,
        order: 7,
        instrumentId: 11567,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 26,
        order: 8,
        instrumentId: 11401,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 27,
        order: 9,
        instrumentId: 11398,
        productId: 'retirement',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    }, // RA End
    {
        id: 28,
        order: 1,
        instrumentId: 11567,
        productId: 'offshore-rand-based-funds',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 50000,
            minimumInvestment: 50000,
        },
    },
    {
        id: 29,
        order: 2,
        instrumentId: 11401,
        productId: 'offshore-rand-based-funds',
        riskSliderPosition: FundSliderPosition.Medium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 30,
        order: 3,
        instrumentId: 11398,
        productId: 'offshore-rand-based-funds',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    }, // Offshore rand based funds end
    {
        id: 31,
        order: 1,
        instrumentId: 12381,
        productId: 'offshore-foreign-currency-funds',
        riskSliderPosition: FundSliderPosition.Low,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 50000,
            minimumInvestment: 50000,
        },
    },
    {
        id: 32,
        order: 2,
        instrumentId: 12360,
        productId: 'offshore-foreign-currency-funds',
        riskSliderPosition: FundSliderPosition.Medium,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    },
    {
        id: 33,
        order: 3,
        instrumentId: 12375,
        productId: 'offshore-foreign-currency-funds',
        riskSliderPosition: FundSliderPosition.High,
        keyFacts: {
            currency: 'zar',
            minimumInitial: 10000,
            minimumInvestment: 10000,
        },
    }, // Offshore foreign currency funds end
];
