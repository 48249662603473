import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProduct } from '../models';

@Injectable({ providedIn: 'root' })
export class ProductsService {
    constructor(private http: HttpClient) {}

    getGetProducts(): Observable<IProduct[]> {
        return this.http.get(`api/products`).pipe(map((response: any) => response as IProduct[]));
    }

    getGetProductsByInvestmentTypeId(journeyId: string): Observable<IProduct[]> {
        return this.http.get(`api/products?journeyId=${journeyId}`).pipe(map((response: any) => response as IProduct[]));
    }
}
