import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { FundsState, IFund } from '../../models';
import * as FundActions from '../actions/funds.actions';

export const fundEntityAdapter: EntityAdapter<IFund> = createEntityAdapter<IFund>();

export const fundInitialState: FundsState = fundEntityAdapter.getInitialState({
    loading: false,
    selectedFundIds: [],
});

const fundReducer = createReducer(
    fundInitialState,
    on(FundActions.setSelectedFunds, (state, { id }) => {
        const index = state.selectedFundIds.indexOf(id);
        if (index === -1) {
            return Object.assign({}, state, { selectedFundIds: [...state.selectedFundIds, id] });
        }
        return state;
    }),
    on(FundActions.removeSelectedFunds, (state, { id }) => {
        const index = state.selectedFundIds.indexOf(id);
        if (index !== -1) {
            return Object.assign({}, state, { selectedFundIds: state.selectedFundIds.filter((item) => item !== id) });
        }
        return state;
    }),
    on(FundActions.clearSelectedFunds, (state) => {
        return Object.assign({}, state, { selectedFundIds: [] });
    }),
    on(FundActions.loadFundsSuccess, (state, { funds }) => {
        return fundEntityAdapter.setAll(funds, Object.assign({}, state, { loading: false }));
    }),
    on(FundActions.clearFunds, (state) => {
        return fundEntityAdapter.removeAll({ ...state, selectedFundIds: [] });
    }),
    on(FundActions.loadFunds, (state) => {
        return Object.assign({}, state, { loading: true });
    }),
);

export function FundsReducer(state: FundsState | undefined, action: Action): any {
    return fundReducer(state, action);
}

const getSelectedFundIds = (state: FundsState) => state.selectedFundIds;

const fundEntityAdapterSelectors = fundEntityAdapter.getSelectors();

export const FundSelectors = {
    selectFundIds: fundEntityAdapterSelectors.selectIds,
    selectFundEntities: fundEntityAdapterSelectors.selectEntities,
    selectAllFunds: fundEntityAdapterSelectors.selectAll,
    selectTotalFunds: fundEntityAdapterSelectors.selectTotal,
    getSelectedFundIds: getSelectedFundIds,
};
