import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SliderComponent } from './slider.component';
@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule, NgxSliderModule, MatTooltipModule],
    declarations: [SliderComponent],
    exports: [SliderComponent, MatProgressSpinnerModule, MatTooltipModule],
})
export class SliderModule {}
