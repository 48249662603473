import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IJourney } from '../models';

@Injectable({ providedIn: 'root' })
export class JourneysService {
    constructor(private http: HttpClient) {}

    getJourneys(): Observable<IJourney[]> {
        return this.http.get(`api/journeys`).pipe(map((response: any) => response as IJourney[]));
    }
}
