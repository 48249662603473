import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Actions from '../../store/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-default-layout',
    templateUrl: './page-layout.component.html',
})
export class PageLayoutComponent implements OnInit, OnDestroy {
    pageLayoutType: pageLayoutTypes;
    title: string;

    constructor(private store: Store, private renderer: Renderer2, protected route: ActivatedRoute) {
        this.pageLayoutType = this.route.snapshot.data['pageLayoutType'] || 'default-layout';
        this.title = this.route.snapshot.data['title'];
        this.renderer.addClass(document.body, this.pageLayoutType);
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, this.pageLayoutType);
    }

    ngOnInit(): void {
        this.store.dispatch(Actions.loadRiskMappings());
        this.store.dispatch(Actions.loadJourneys());
        this.store.dispatch(Actions.loadProducts());
    }
}

type pageLayoutTypes = 'default-layout' | 'white-layout';
