// export enum ApiRole {
//     Employer = 1,
//     ModelPortfolioManager = 2,
//     ViewInvestor = 3,
//     TransactInvestor = 4,
//     StaffInvestor = 5,
//     ViewAdvisor = 6,
//     TransactAdvisor = 7,
//     DiscretionaryAdvisor = 8,
//     Assistant = 9,
//     FirmSuperUser = 11,
//     GroupSuperUser = 12,
//     DiscretionaryManager = 13,
//     DiscretionaryManagerAssistant = 14,
//     InvestecConsultant = 15,
//     InvestecAdministrator = 16,
//     InvestecManager = 17,
//     SilicaAdministrator = 18,
//     SilicaInternalSystem = 19,
//     UserManager = 20,
//     TestUser = 33,
//     GuestInvestor = 34,
// }

// export enum CommonLobRole {
//     Employer = 'Employer',
//     ModelPortfolioManager = 'ModelPortfolioManager',
//     Investor = 'Investor',
//     Advisor = 'Advisor',
//     Assistant = 'Assistant',
//     SuperUser = 'SuperUser',
//     DiscretionaryManager = 'DiscretionaryManager',
//     DiscretionaryManagerAssistant = 'DiscretionaryManagerAssistant',
//     Consultant = 'Consultant',
//     Administrator = 'Administrator',
//     UserManager = 'UserManager',
//     GuestInvestor = 'GuestInvestor',
//     GroupSuperUser = 'GroupSuperUser',
// }

export enum CommonLobSystem {
    IMS = 'IMS',
    IFM = 'IFM',
    WEB = 'WEB',
}

// export enum LobSystemId {
//     SWAP = 'SWAP',
//     SAIP = 'SAIP',
//     WEB = 'WEB',
// }

// export enum ApiLobSystemId {
//     SWAP = 0,
//     SAIP = 1,
//     WEB = 2,
// }

// export enum CommonLobSystemNinetyOne {
//     IMS = '91IP',
//     IFM = '91FM',
//     WEB = 'WEB',
// }
