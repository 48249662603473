import { ActionReducerMap } from '@ngrx/store';
import { FundsState, JourneyState, ProductsState, RiskMappingsState, RisksState } from 'src/app/models';
import * as reducers from './reducers';

export interface State {
    journeys: JourneyState;
    products: ProductsState;
    funds: FundsState;
    risks: RisksState;
    riskMappings: RiskMappingsState;
}

export const mappedReducers: ActionReducerMap<State> = {
    journeys: reducers.JourneysReducer,
    products: reducers.ProductsReducer,
    funds: reducers.FundsReducer,
    risks: reducers.RisksReducer,
    riskMappings: reducers.RiskMappingsReducer,
};
