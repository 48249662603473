import { IRiskMapping } from 'src/app/models';
import { FundSliderPosition } from '../configuredFunds';

export const riskMappings: IRiskMapping[] = [
    {
        id: 0,
        appliesTo: 'default',
        min: 1,
        max: 5,
        risks: [
            {
                id: 1,
                value: FundSliderPosition.Low,
                display: 'Low',
                showLegend: true,
            },
            {
                id: 2,
                value: FundSliderPosition.LowMedium,
                display: 'Low medium',
                showLegend: false,
            },
            {
                id: 3,
                value: FundSliderPosition.Medium,
                display: 'Medium',
                showLegend: true,
            },
            {
                id: 4,
                value: FundSliderPosition.MediumHigh,
                display: 'Medium high',
                showLegend: false,
            },
            {
                id: 5,
                value: FundSliderPosition.High,
                display: 'High',
                showLegend: true,
            },
        ],
    },
    {
        id: 1,
        appliesTo: 'retirement',
        min: 1,
        max: 5,
        risks: [
            {
                id: 1,
                value: FundSliderPosition.Low,
                display: 'Low',
                showLegend: true,
            },
            {
                id: 2,
                value: FundSliderPosition.LowMedium,
                display: 'Low medium',
                showLegend: false,
            },
            {
                id: 3,
                value: FundSliderPosition.Medium,
                display: 'Medium',
                showLegend: true,
            },
            {
                id: 4,
                value: FundSliderPosition.MediumHigh,
                display: 'Medium high',
                showLegend: false,
            },
            {
                id: 5,
                value: FundSliderPosition.High,
                display: 'High',
                showLegend: true,
            },
        ],
    },
    {
        id: 2,
        appliesTo: 'offshore-rand-based-funds',
        min: 1,
        max: 5,
        risks: [
            {
                id: 1,
                value: FundSliderPosition.Low,
                display: 'Low',
                showLegend: true,
            },
            {
                id: 3,
                value: FundSliderPosition.Medium,
                display: 'Medium',
                showLegend: true,
            },
            {
                id: 5,
                value: FundSliderPosition.High,
                display: 'High',
                showLegend: true,
            },
        ],
    },
    {
        id: 2,
        appliesTo: 'offshore-foreign-currency-funds',
        min: 1,
        max: 5,
        risks: [
            {
                id: 1,
                value: FundSliderPosition.Low,
                display: 'Low',
                showLegend: true,
            },
            {
                id: 3,
                value: FundSliderPosition.Medium,
                display: 'Medium',
                showLegend: true,
            },
            {
                id: 5,
                value: FundSliderPosition.High,
                display: 'High',
                showLegend: true,
            },
        ],
    },
];
