import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormPasswordInputComponent } from '../form-password-input/form-password-input.component';
import { FormPhoneInputComponent } from '../form-phone-input/form-phone-input.component';
import { SharedModule } from '../shared.module';
import { MaterialImportModule } from './material-import/material-import.module';

const defaultCurrencyMaskConfig: CurrencyMaskConfig = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    nullable: false,
    precision: 2,
    prefix: 'R ',
    suffix: '',
    thousands: ' ',
    inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
    declarations: [FormPhoneInputComponent, FormInputComponent, FormPasswordInputComponent],
    imports: [
        CommonModule,
        MaterialImportModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgSelectModule,
        NgxCurrencyModule.forRoot(defaultCurrencyMaskConfig),
        RouterModule,
    ],
    exports: [FormPhoneInputComponent, FormInputComponent, FormPasswordInputComponent],
})
export class FormsFrameworkModule {}
