import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IRiskMapping, RiskMappingsState } from '../../models';
import * as RiskMappingActions from '../actions/risk-mapping.actions';

export const riskMappingEntityAdapter: EntityAdapter<IRiskMapping> = createEntityAdapter<IRiskMapping>();

export const riskMappingInitialState: RiskMappingsState = riskMappingEntityAdapter.getInitialState({
    selectedRiskMappingId: 0,
});

const riskMappingsReducer = createReducer(
    riskMappingInitialState,
    on(RiskMappingActions.setSelectedRiskMapping, (state, { id }) => {
        return Object.assign({}, state, { selectedRiskMappingId: id });
    }),
    on(RiskMappingActions.clearSelectedRiskMapping, (state) => {
        return Object.assign({}, state, { selectedRiskMappingId: 0 });
    }),
    on(RiskMappingActions.loadRiskMappingsSuccess, (state, { riskMappings }) => {
        return riskMappingEntityAdapter.setAll(riskMappings, state);
    }),
);

export function RiskMappingsReducer(state: RiskMappingsState | undefined, action: Action): any {
    return riskMappingsReducer(state, action);
}

const getSelectedRiskMappingId = (state: RiskMappingsState) => state.selectedRiskMappingId;

const riskMappingEntityAdapterSelectors = riskMappingEntityAdapter.getSelectors();

export const RiskMappingsSelectors = {
    selectRiskMappingIds: riskMappingEntityAdapterSelectors.selectIds,
    selectRiskMappingEntities: riskMappingEntityAdapterSelectors.selectEntities,
    selectAllRiskMappings: riskMappingEntityAdapterSelectors.selectAll,
    selectTotalRiskMappings: riskMappingEntityAdapterSelectors.selectTotal,
    selectCurrentRiskMappingId: getSelectedRiskMappingId,
};
