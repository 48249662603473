import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IFee, IFund } from '../models';
import { configuredFunds, fundProductLookups } from '../data/configuredFunds';
import * as _ from 'underscore';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FundsService {
    private readonly relativeUrl = '/Funds';

    constructor(private readonly httpClient: HttpClient) {}

    getFunds(productId: string): Observable<IFund[]> {
        const funds = fundProductLookups
            .map((fpl) => {
                const fund = Object.assign({}, fpl);
                const configuredFund = configuredFunds.find((i) => i.instrumentId === fund.instrumentId);
                return Object.assign(fund, configuredFund);
            })
            .filter((f) => f.productId === productId);
        return of(funds);
    }

    getFees(funds: IFund[]): Observable<Array<IFee>> {
        const fundSelections = {
            fundSelections: funds.map((fund) => {
                return {
                    typeCode: fund.instrument.selectedInstrument.instrumentTypeCode,
                    number: fund.instrumentId,
                    percentageAllocation: 100,
                };
            }),
        };
        return this.httpClient.post<Array<IFee>>(`${environment.apiConfig.baseUrl}${this.relativeUrl}/fees/managementfees`, fundSelections);
    }
}
