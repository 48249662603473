import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { JourneysService } from '../../services';
import * as fromActions from '../actions';

@Injectable()
export class JourneysEffects {
    constructor(private actions$: Actions, private journeysService: JourneysService) {}

    loadAllJourneys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadJourneys),
            mergeMap(() =>
                this.journeysService.getJourneys().pipe(
                    map((journeys) => fromActions.loadJourneysSuccess({ journeys: journeys })),
                    catchError(() => of(fromActions.loadJourneysError())),
                ),
            ),
        ),
    );
}
