import { Inject, Injectable } from '@angular/core';
import 'src/app/extensions/window-extensions';
import { WindowProvider } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor(@Inject(WindowProvider) private readonly window: Window) {}

    track(url: string): void {
        if (this.window.gtag) {
            this.window.gtag('config', environment.googleAnalytics.key, { page_path: url });
        }
    }
}
