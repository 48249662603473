import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectFormFieldControlDirective } from '../../directives/ng-select-form-field.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [NgSelectFormFieldControlDirective],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        // MatIconModule,
        // MatToolbarModule,
        // MatDividerModule,
        // MatSidenavModule,
        // MatListModule,
        MatSnackBarModule,
        MatDialogModule,
        NgSelectModule,
        MatTooltipModule,
    ],
    exports: [
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        // MatButtonModule,
        MatProgressSpinnerModule,
        // MatIconModule,
        // MatToolbarModule,
        // MatDividerModule,
        // MatSidenavModule,
        // MatListModule,
        MatSnackBarModule,
        MatDialogModule,
        NgSelectModule,
        NgSelectFormFieldControlDirective,
        MatTooltipModule,
    ],
})
export class MaterialImportModule {}
