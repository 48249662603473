import { InMemoryDbService } from 'angular-in-memory-web-api';
import { journeys } from './journeys-db.service';
import { products } from './products-db.service';
import { riskMappings } from './risks-db.service';

export class InMemoryProductsDataService implements InMemoryDbService {
    createDb(): any {
        return { journeys, products, riskMappings };
    }
}
