import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GoogleAnalyticsService } from './data/services/google-analytics.service';
import { AppService } from './app.service';

@Component({
    selector: 'body',
    template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'We are Ninety One | Ninety One';
    description =
        'We are active investors. We focus on where we can make the biggest difference for our clients. Investing for a world of change. Capital at risk.';

    destroySubject$ = new Subject<void>();

    constructor(
        private readonly appService: AppService,
        private readonly router: Router,
        private readonly googleAnalyticsService: GoogleAnalyticsService,
    ) {}

    ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    ngOnInit(): void {
        this.appService.registerRouterHooks(this.router);

        this.router.events
            .pipe(
                takeUntil(this.destroySubject$),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
                if (event?.urlAfterRedirects) {
                    this.googleAnalyticsService.track(event?.urlAfterRedirects);
                }
            });
    }
}
