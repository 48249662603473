import { createAction, props } from '@ngrx/store';
import { IFund } from '../../models';

export const setSelectedFunds = createAction('funds.selected.set', props<{ id: number }>());
export const removeSelectedFunds = createAction('funds.selected.remove', props<{ id: number }>());
export const clearSelectedFunds = createAction('funds.selected.clear');

export const clearFunds = createAction('funds.clear');

export const loadFunds = createAction('funds.load', props<{ productId: string }>());
export const loadFundsSuccess = createAction('funds.load.success', props<{ funds: IFund[] }>());
export const loadFundsError = createAction('funds.load.error');
