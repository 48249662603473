import { createFeatureSelector, createSelector } from '@ngrx/store';
import { JourneyState } from '../../models';
import { JourneySelectors } from '../reducers';

export const selectJourneyState = createFeatureSelector<JourneyState>('journeys');

export const selectJourneyIds = createSelector(selectJourneyState, JourneySelectors.selectJourneyIds);
export const selectJourneyEntities = createSelector(selectJourneyState, JourneySelectors.selectJourneyEntities);
export const selectAllJourneys = createSelector(selectJourneyState, JourneySelectors.selectAllJourneys);
export const selectJourneyTotal = createSelector(selectJourneyState, JourneySelectors.selectTotalJourneys);
export const selectCurrentJourneyId = createSelector(selectJourneyState, JourneySelectors.getSelectedJourneyId);

export const selectCurrentJourney = createSelector(
    selectJourneyEntities,
    selectCurrentJourneyId,
    (journeyEntities, journeyId) => journeyEntities[journeyId],
);
