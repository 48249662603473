import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { InMemoryProductsDataService } from './data';
import { PageLayoutComponent } from './layout';
import { HeaderModule } from './layout/header/header.module';
import { MonitoringErrorHandler } from './monitoring-error.handler';
import { ButtonModule } from './shared/button/button.module';
import { WindowProvider } from './shared/constants';
import { FormsFrameworkModule } from './shared/forms-framework/forms-framework.module';
import { MaterialImportModule } from './shared/forms-framework/material-import/material-import.module';
import { SliderComponent } from './shared/slider/slider.component';
import { SliderModule } from './shared/slider/slider.module';
import { Effects, Middleware } from './store';
import { mappedReducers } from './store/state';
import { LandingPageComponent } from './views';
import { NewCustomerLandingPageComponent } from './views/new-customer-landing-page/new-customer-landing-page.component';
import { RegistrationDetailsComponent } from './views/registration/components/registration-details/registration-details.component';
import { NewCustomerRegistrationComponent } from './views/registration/new-customer-registration/new-customer-registration.component';
import { RegistrationFailedComponent } from './views/registration/registration-failed/registration-failed.component';
import { RegistrationSuccessComponent } from './views/registration/registration-success/registration-success.component';
import { UserSelectionPageComponent } from './views/user-selection-page/user-selection-page.component';
import { SharedModule } from './shared/shared.module';

const APP_CONTAINERS = [PageLayoutComponent];

const USE_LOCAL_STORAGE_FOR_NGRX = environment.useLocalStorageForNgrx;
const metaReducers: MetaReducer[] = USE_LOCAL_STORAGE_FOR_NGRX ? [Middleware.storageMetaReducer] : [];

@NgModule({
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        LandingPageComponent,
        UserSelectionPageComponent,
        NewCustomerLandingPageComponent,
        NewCustomerRegistrationComponent,
        RegistrationSuccessComponent,
        RegistrationFailedComponent,
        RegistrationDetailsComponent,
    ],
    imports: [
        ButtonModule,
        MaterialImportModule,
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,

        StoreModule.forRoot(mappedReducers, { metaReducers }),
        EffectsModule.forRoot([Effects.JourneysEffects, Effects.ProductsEffects, Effects.FundsEffects, Effects.RiskMappingsEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 50,
        }),
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(InMemoryProductsDataService, {
            passThruUnknownUrl: true,
        }),
        PdfViewerModule,
        FlexLayoutModule,
        HeaderModule,
        SliderModule,
        MaterialImportModule,
        FormsModule,
        ReactiveFormsModule,
        FormsFrameworkModule,
        SharedModule,
    ],

    providers: [
        MatDialog,
        MatSnackBar,
        {
            provide: ErrorHandler,
            useClass: MonitoringErrorHandler,
        },
        {
            provide: WindowProvider,
            useFactory: () => window,
        },
        {
            provide: 'password-validation-settings',
            useValue: {
                MinLength: 8,
                RequireNonLetterOrDigit: true,
                RequireDigit: true,
                RequireLowercase: true,
                RequireUppercase: true,
                RequireSpecialCharacters: true,
            },
        },
    ],

    bootstrap: [AppComponent],

    exports: [SliderComponent],
})
export class AppModule {}
