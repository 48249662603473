import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RiskMappingsService } from 'src/app/services/risk-mappings.service';
import * as fromActions from '../actions';

@Injectable()
export class RiskMappingsEffects {
    constructor(private actions$: Actions, private riskMappingsService: RiskMappingsService) {}

    loadAllRiskMappings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadRiskMappings),
            mergeMap(() =>
                this.riskMappingsService.getRiskMappings().pipe(
                    map((riskMappings) => fromActions.loadRiskMappingsSuccess({ riskMappings: riskMappings })),
                    catchError(() => of(fromActions.loadRiskMappingsError())),
                ),
            ),
        ),
    );
}
