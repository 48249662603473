import { IJourney } from '../../models';

export const journeys: IJourney[] = [
    {
        id: 'just-investing',
        journeyTitle: 'Just investing',
        text: 'I don’t have an exact goal in mind, I want to just invest for now.',
        infoText: '',
        buttonText: 'Select',
        selected: false,
    },
    {
        id: 'tax-free',
        journeyTitle: 'Tax free',
        text: 'No capital gains tax (CGT) or tax is levied on the dividends and interest received.',
        infoText: 'Please note: you can’t do this on a minor’s behalf as of yet.',
        buttonText: 'Select',
        selected: false,
    },
    {
        id: 'retirement',
        journeyTitle: 'Retirement',
        text: 'Benefit from tax-efficient solutions that can help you make the most of your savings to provision for a comfortable retirement.',
        infoText: '',
        buttonText: 'Select',
        selected: false,
    },
    {
        id: 'income',
        journeyTitle: 'Income',
        text: 'Enjoy a regular income stream with these product offerings.',
        infoText: '',
        buttonText: 'Select',
        selected: false,
    },
    {
        id: 'offshore',
        journeyTitle: 'Offshore',
        text: 'Diversify your investments by gaining exposure to offshore markets.',
        infoText: '',
        buttonText: 'Select',
        selected: false,
    },
];
