import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FundsState } from '../../models';
import { FundSelectors } from '../reducers';

export const selectFundState = createFeatureSelector<FundsState>('funds');

export const selectFundIds = createSelector(selectFundState, FundSelectors.selectFundIds);
export const selectFundEntities = createSelector(selectFundState, FundSelectors.selectFundEntities);
export const selectAllFunds = createSelector(selectFundState, FundSelectors.selectAllFunds);
export const selectTotalFunds = createSelector(selectFundState, FundSelectors.selectTotalFunds);
export const getSelectedFundIds = createSelector(selectFundState, FundSelectors.getSelectedFundIds);

export const selectFundLoading = createSelector(selectFundState, (fundState) => fundState.loading);
