import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsState } from '../../models';
import { ProductSelectors } from '../reducers';

export const selectProductState = createFeatureSelector<ProductsState>('products');

export const selectProductIds = createSelector(selectProductState, ProductSelectors.selectProductIds);
export const selectProductEntities = createSelector(selectProductState, ProductSelectors.selectProductEntities);
export const selectAllProducts = createSelector(selectProductState, ProductSelectors.selectAllProducts);
export const selectTotalProducts = createSelector(selectProductState, ProductSelectors.selectTotalProducts);
export const getSelectedProductId = createSelector(selectProductState, ProductSelectors.getSelectedProductId);

export const selectCurrentProduct = createSelector(
    selectProductEntities,
    getSelectedProductId,
    (productEntities, productId) => productEntities[productId],
);
